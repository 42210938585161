import store from "@/store";
function verifyPermissions(to, from, next) {
  var _a, _b, _c, _d, _e, _f;
  const specialPermission = store.state.me.user.is_prepayroll_admin || havePermission(
    "approve_incidences",
    "reject_incidences",
    "edit_incidences",
    "create_incidences",
    "force_reject_incidences",
    "force_approve_incidences"
  );
  const companyPermission = ((_c = (_b = (_a = store.state.company) == null ? void 0 : _a.permissions) == null ? void 0 : _b.myPermissions) == null ? void 0 : _c.filter(
    (permission) => permission.slug.includes("incidences") && permission.policy === "disallow"
  ).length) > 0;
  if (companyPermission) {
    return next({ name: "not-allowed", replace: true, params: { isNotAllowed: true } });
  }
  if (!specialPermission && store.state.me.user.role !== "HRA") {
    return next({ name: "not-allowed", replace: true, params: { isNotAllowed: true } });
  }
  if (!((_f = (_e = (_d = store.state.company) == null ? void 0 : _d.permissions) == null ? void 0 : _e.myPermissions) == null ? void 0 : _f.find(
    (permission) => permission.field_or_action === "incidences"
  )) && !store.state.me.user.is_prepayroll_admin) {
    return next();
  }
  return next();
}
function havePermission(...permissions) {
  return store.state.me.user.access_permissions.map((permission) => permission.field_or_action).some((permission) => permissions.includes(permission));
}
const routes = {
  path: "/Solicitudes-e-incidencias2",
  name: "requestsIncidentsV3",
  component: () => import(
    /* webpackChunkName: "timeoff" */
    "@/views/customRequestsIncidents2/RequestsAndIncidencesLayout"
  ),
  redirect: { name: "company-custom-requests2" },
  children: [
    {
      path: "empresa",
      name: "company-custom-requests2",
      beforeEnter: verifyPermissions,
      component: {
        render: (h) => h("router-view")
      },
      redirect: {
        name: "company-pending-requests2"
      },
      children: [
        {
          path: "pendientes",
          name: "company-pending-requests2",
          component: () => import(
            /* webpackChunkName: "timeoff" */
            "@/views/customRequestsIncidents2/PendingRequestsView"
          ),
          beforeEnter: (to, from, next) => {
            const permiso = havePermission(
              "approve_incidences",
              "reject_incidences",
              "edit_incidences",
              "force_reject_incidences",
              "force_approve_incidences"
            ) || store.state.me.user.role === "HRA";
            if (!permiso) {
              if (store.state.me.user.is_prepayroll_admin || havePermission("create_incidences")) {
                next({ name: "company-detail-table2" });
              } else
                next({ name: "not-allowed", replace: false, query: { isNotAllowed: true } });
            } else
              next();
          },
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Solicitudes / Incidencias"
          }
        },
        {
          path: "vista-particular",
          redirect: {
            name: "company-detail-table2"
          }
        },
        {
          path: "resueltas",
          name: "company-detail-table2",
          component: () => import(
            /* webpackChunkName: "timeoff" */
            "@/views/customRequestsIncidents2/IncidencesDetailView"
          ),
          beforeEnter: (to, from, next) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            const permiso = havePermission("create_incidences") || ((_d = (_c = (_b = (_a = store) == null ? void 0 : _a.state) == null ? void 0 : _b.me) == null ? void 0 : _c.user) == null ? void 0 : _d.role) === "HRA" || ((_h = (_g = (_f = (_e = store) == null ? void 0 : _e.state) == null ? void 0 : _f.me) == null ? void 0 : _g.user) == null ? void 0 : _h.is_prepayroll_admin);
            if (!permiso) {
              next({ name: "not-allowed", replace: true, params: { isNotAllowed: true } });
            } else
              next();
          },
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Solicitudes / Incidencias"
          }
        },
        {
          path: "vista-general",
          name: "company-incidences-by-employee-table2",
          component: () => import(
            /* webpackChunkName: "timeoff" */
            "@/views/customRequestsIncidents2/IncidencesTableView"
          ),
          beforeEnter: (to, from, next) => {
            const permiso = store.state.me.user.role === "HRA";
            if (!permiso) {
              next({ name: "not-allowed", replace: true, params: { isNotAllowed: true } });
            } else
              next();
          },
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Solicitudes / Incidencias"
          }
        },
        {
          path: "cambios-vacaciones",
          name: "company-edit-requests-table2",
          component: () => import(
            /* webpackChunkName: "timeoff" */
            "@/views/customRequestsIncidents2/ChangeRequestsView"
          ),
          beforeEnter: (to, from, next) => {
            const permiso = store.state.me.user.role === "HRA";
            if (!permiso) {
              next({ name: "not-allowed", replace: true, params: { isNotAllowed: true } });
            } else
              next();
          },
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Solicitudes / Incidencias"
          }
        },
        {
          path: "incidencias-masivas",
          name: "massive-incidences2",
          component: () => import(
            /* webpackChunkName: "timeoff" */
            "@/views/customRequestsIncidents2/MassiveIncidences"
          ),
          beforeEnter: (to, from, next) => {
            const permiso = store.state.me.user.role === "HRA";
            if (!permiso) {
              next({ name: "not-allowed", replace: true, params: { isNotAllowed: true } });
            } else
              next();
          },
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Carga masiva de incidencias",
            backTo: "company-detail-table2"
          }
        }
      ]
    },
    {
      path: "empresa-balances-de-vacaciones",
      name: "company-vacation-balance-2",
      component: {
        render: (h) => h("router-view")
      },
      beforeEnter: verifyPermissions,
      redirect: {
        name: "company-vacation-balance2"
      },
      children: [
        {
          path: "",
          name: "company-vacation-balance2",
          component: () => import(
            /* webpackChunkName: "timeoff" */
            "@/views/customRequestsIncidents2/VacationsBalance"
          ),
          beforeEnter: (to, from, next) => {
            const permiso = store.state.me.user.role === "HRA";
            if (!permiso) {
              next({ name: "not-allowed", replace: true, params: { isNotAllowed: true } });
            } else
              next();
          },
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Balances de vacaciones"
          }
        },
        {
          path: "detalle-balance-de-vacaciones",
          name: "vacation-balance-detail2",
          component: () => import(
            /* webpackChunkName: "timeoff" */
            "@/views/customRequestsIncidents2/VacationsBalanceDetail"
          ),
          beforeEnter: (to, from, next) => {
            const permiso = store.state.me.user.role === "HRA";
            if (!permiso) {
              next({ name: "not-allowed", replace: true, params: { isNotAllowed: true } });
            } else
              next();
          },
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Detalle del balance de vacaciones",
            backTo: "company-vacation-balance2",
            props: true,
            exitOnReload: true
          }
        },
        {
          path: "ajustar-balance-de-vacaciones",
          name: "company-adjustment-balance2",
          component: () => import(
            /* webpackChunkName: "timeoff" */
            "@/views/customRequestsIncidents2/VacationsBalanceAdjustment"
          ),
          beforeEnter: (to, from, next) => {
            const permiso = store.state.me.user.role === "HRA";
            if (!permiso) {
              next({ name: "not-allowed", replace: true, params: { isNotAllowed: true } });
            } else
              next();
          },
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Ajuste inicial de vacaciones",
            backTo: "vacation-policies"
          }
        }
      ]
    },
    {
      path: "equipo-balances-de-vacaciones",
      name: "team-vacation-balance-2",
      component: {
        render: (h) => h("router-view")
      },
      beforeEnter: (to, from, next) => {
        const permiso = store.state.me.user.is_boss;
        if (!permiso) {
          next({ name: "not-allowed", replace: true, params: { isNotAllowed: true } });
        } else
          next();
      },
      redirect: {
        name: "team-vacation-balance2"
      },
      children: [
        {
          path: "",
          name: "team-vacation-balance2",
          component: () => import(
            /* webpackChunkName: "timeoff" */
            "@/views/customRequestsIncidents2/VacationsBalance"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Balance de vacaciones",
            validateTab: {
              key: "tab_vacation_balance",
              subMenu: "vacation_balance",
              level: "boss"
            }
          }
        },
        {
          path: "detalle-balance-de-vacaciones",
          name: "team-vacation-balance-detail2",
          component: () => import(
            /* webpackChunkName: "timeoff" */
            "@/views/customRequestsIncidents2/VacationsBalanceDetail"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Detalle del balance de vacaciones",
            backTo: "team-vacation-balance2",
            props: true,
            exitOnReload: true,
            validateTab: {
              key: "tab_vacation_balance",
              subMenu: "vacation_balance",
              level: "boss"
            }
          }
        }
      ]
    },
    {
      path: "mi-equipo",
      name: "team-custom-requests2",
      component: {
        render: (h) => h("router-view")
      },
      beforeEnter: (to, from, next) => {
        const permiso = store.state.me.user.is_boss;
        if (!permiso) {
          next({ name: "not-allowed", replace: true, params: { isNotAllowed: true } });
        } else
          next();
      },
      redirect: {
        name: "team-pending-requests2"
      },
      children: [
        {
          path: "pendientes",
          name: "team-pending-requests2",
          component: () => import(
            /* webpackChunkName: "timeoff" */
            "@/views/customRequestsIncidents2/PendingRequestsView"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Solicitudes / Incidencias",
            onlyMyCompany: true
          }
        },
        {
          path: "vista-particular",
          redirect: {
            name: "team-detail-table2"
          }
        },
        {
          path: "vista-particular/:id",
          redirect: {
            name: "team-pending-requests2"
          }
        },
        {
          path: "resueltas",
          name: "team-detail-table2",
          component: () => import(
            /* webpackChunkName: "timeoff" */
            "@/views/customRequestsIncidents2/IncidencesDetailView"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Solicitudes / Incidencias",
            onlyMyCompany: true,
            validateTab: {
              key: "tab_resolved",
              subMenu: "team",
              level: "boss"
            }
          }
        },
        {
          path: "vista-general",
          name: "team-requests-table2",
          component: () => import(
            /* webpackChunkName: "timeoff" */
            "@/views/customRequestsIncidents2/IncidencesTableView"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Solicitudes / Incidencias",
            onlyMyCompany: true,
            validateTab: {
              key: "tab_general_view",
              subMenu: "team",
              level: "boss"
            }
          }
        },
        {
          path: "cambios-vacaciones",
          name: "team-edit-requests-table2",
          component: () => import(
            /* webpackChunkName: "timeoff" */
            "@/views/customRequestsIncidents2/ChangeRequestsView"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Solicitudes / Incidencias",
            onlyMyCompany: true,
            validateTab: {
              key: "tab_vacation_change",
              subMenu: "team",
              level: "boss"
            }
          }
        },
        {
          path: "incidencias-masivas",
          name: "team-massive-incidences2",
          component: () => import(
            /* webpackChunkName: "timeoff" */
            "@/views/customRequestsIncidents2/MassiveIncidences"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Carga masiva de incidencias",
            backTo: "team-detail-table2",
            onlyMyCompany: true
          }
        }
      ]
    },
    {
      path: "Mis-solicitudes",
      name: "my-requests2",
      component: {
        render: (h) => h("router-view")
      },
      redirect: {
        name: "my-requestable-requests2"
      },
      children: [
        {
          path: "solicitar",
          name: "my-requestable-requests2",
          component: () => import(
            /* webpackChunkName: "timeoff" */
            "@/views/customRequestsIncidents2/RequestableRequestListView"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Mis solicitudes / Incidencias",
            onlyMyCompany: true
          }
        },
        {
          path: "proximas",
          name: "my-upcoming-requests2",
          component: () => import(
            /* webpackChunkName: "timeoff" */
            "@/views/customRequestsIncidents2/MyUpcomingRequestsView"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Mis solicitudes / Incidencias",
            onlyMyCompany: true
          }
        },
        {
          path: "anteriores",
          name: "my-previous-requests2",
          component: () => import(
            /* webpackChunkName: "timeoff" */
            "@/views/customRequestsIncidents2/MyPreviousRequestsView"
          ),
          meta: {
            requireAuth: true,
            module: "time_off",
            publicName: "Mis solicitudes / Incidencias",
            onlyMyCompany: true,
            validateTab: {
              key: "tab_previous",
              subMenu: "my_requests",
              level: "both"
            }
          }
        }
      ]
    },
    {
      path: "mis-registros-reloj-checador",
      name: "my-time-clock-records2",
      component: () => import(
        /* webpackChunkName: "timeoff" */
        "@/views/customRequestsIncidents2/MyTimeClockRecordsView"
      ),
      meta: {
        requireAuth: true,
        module: "time_off",
        publicName: "Mis registros de reloj checador"
      }
    },
    {
      path: "pendientes",
      name: "approver-pending-requests2",
      component: () => import(
        /* webpackChunkName: "timeoff" */
        "@/views/customRequestsIncidents2/PendingRequestsView"
      ),
      meta: {
        requireAuth: true,
        module: "time_off",
        publicName: "Solicitudes por aprobar"
      }
    },
    {
      path: "resueltas",
      name: "approver-resolved-requests2",
      component: () => import("@/views/customRequestsIncidents2/IncidencesDetailView"),
      meta: {
        requireAuth: true,
        module: "time_off",
        publicName: "Solicitudes resueltas",
        validateTab: {
          key: "tab_resolved",
          subMenu: "requests_to_approve",
          level: "both"
        }
      }
    }
  ]
};
export default routes;
