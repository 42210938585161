var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "mb-6 mt-1" }, [_vm._l(_vm.internalFiles, function(file) {
    return _c("div", { key: file.id, staticClass: "w-full my-2 rounded-md flex items-center border border-grey p-2 h-12" }, [_c("IconCheck", { staticClass: "h-4 w-4 inline mr-2" }), _c("span", { staticClass: "cursor-pointer", on: { "click": function($event) {
      return _vm.downloadFile(file);
    } } }, [_vm._v(" " + _vm._s(file.filename || file.name ? _vm.custom ? file.name : file.filename : "Ingresa el documento") + " ")]), _c("IconDelete", { staticClass: "inline ml-auto h-2 w-2 text-grey3 cursor-pointer", on: { "click": function($event) {
      return _vm.deleteFile(file);
    } } })], 1);
  }), _c("label", { staticClass: "cursor-pointer text-[var(--link-color)]", attrs: { "for": `upload${_vm.tag}` }, on: { "click": function($event) {
    return _vm.$emit("analytic");
  } } }, [_c("p", { staticClass: "flex items-center relative min-w-32" }, [_c("IconUpload", { staticClass: "inline mr-2 h-6 w-6" }), _vm.loading ? _c("BLoading", { attrs: { "theme": "ghost" } }) : _c("span", [_vm._v(" Subir archivo ")])], 1), _c("input", { ref: `upload${_vm.tag}`, staticStyle: { "display": "none" }, attrs: { "id": `upload${_vm.tag}`, "type": "file", "name": `upload${_vm.tag}`, "disabled": _vm.loading }, on: { "input": _vm.uploadFile } })])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };
