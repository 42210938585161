import store from "@/store";
function verifyFeature(to, from, next) {
  var _a, _b;
  const { user = {} } = ((_b = (_a = store) == null ? void 0 : _a.state) == null ? void 0 : _b.me) || {};
  const { features = [] } = user || {};
  const feature = features.find((e) => (e == null ? void 0 : e.feature) === "evaluations.show_legacy.active") || { active: false };
  if (!(feature == null ? void 0 : feature.active)) {
    next({ name: "not-allowed" });
  } else {
    next();
  }
}
const routes = {
  path: "/evaluaciones",
  name: "evaluationsV2",
  component: {
    render: (h) => h("router-view")
  },
  redirect: { name: "company-evaluations" },
  children: [
    {
      path: "empresa",
      name: "company-evaluations",
      component: {
        render: (h) => h("router-view")
      },
      redirect: { name: "company-evaluations-list" },
      children: [
        {
          path: "evaluaciones",
          name: "company-evaluations-list",
          component: () => import(
            /* webpackChunkName: "evaluations" */
            "@/views/v2/evaluations/EvaluationsListView"
          ),
          meta: {
            requireAuth: true,
            module: "evaluations",
            publicName: "Evaluaciones de la empresa"
          }
        },
        {
          path: "evaluacion/:id",
          name: "summary-evaluation",
          component: () => import(
            /* webpackChunkName: "evaluations" */
            "@/views/v2/evaluations/SummaryEvaluation"
          ),
          meta: {
            requireAuth: true,
            module: "evaluations",
            backTo: "company-evaluations-list"
          },
          props: true,
          redirect: {
            name: "summary-evaluation-status"
          },
          children: [
            {
              path: "estatus",
              name: "summary-evaluation-status",
              component: () => import(
                /* webpackChunkName: "evaluations" */
                "@/views/v2/evaluations/SummaryEvaluationStatus"
              ),
              meta: {
                requireAuth: true,
                module: "evaluations",
                backTo: "company-evaluations-list"
              },
              props: true
            },
            {
              path: "resultados",
              name: "summary-evaluation-results",
              component: () => import(
                /* webpackChunkName: "evaluations" */
                "@/views/v2/evaluations/SummaryEvaluationResults"
              ),
              meta: {
                requireAuth: true,
                module: "evaluations",
                backTo: "company-evaluations-list"
              },
              props: true
            },
            {
              path: "resultados-individuales",
              name: "summary-evaluation-individual-results",
              component: () => import(
                /* webpackChunkName: "evaluations" */
                "@/views/v2/evaluations/SummaryEvaluationIndividualResults"
              ),
              meta: {
                requireAuth: true,
                module: "evaluations",
                backTo: "company-evaluations-list"
              },
              props: true
            }
          ]
        },
        {
          path: "plantillas",
          name: "company-evaluations-templates",
          component: () => import(
            /* webpackChunkName: "evaluations" */
            "@/views/v2/evaluations/EvaluationsTemplatesView"
          ),
          meta: {
            requireAuth: true,
            module: "evaluations",
            publicName: "Evaluaciones de la empresa"
          }
        },
        {
          path: "evaluaciones/crear-nueva/",
          name: "create-evaluation",
          component: () => import(
            /* webpackChunkName: "evaluations" */
            "@/views/v2/evaluations/CreateEvaluationView"
          ),
          meta: {
            requireAuth: true,
            module: "evaluations",
            publicName: "Crear evaluaci\xF3n",
            backTo: "company-evaluations-list"
          },
          props: true
        },
        {
          path: "evaluaciones/confirmacion-crear-nueva/",
          name: "evaluation-preview",
          component: () => import(
            /* webpackChunkName: "evaluations" */
            "@/views/v2/evaluations/CreateEvaluationStepTwo"
          ),
          meta: {
            requireAuth: true,
            module: "evaluations",
            publicName: "Crear evaluaci\xF3n"
          },
          props: true
        },
        {
          path: "plantillas/nueva-plantilla/",
          name: "create-evaluation-template",
          component: () => import(
            /* webpackChunkName: "evaluations" */
            "@/views/v2/evaluations/CreateEvaluationTemplateView"
          ),
          meta: {
            requireAuth: true,
            module: "evaluations",
            publicName: "Nueva plantilla",
            backTo: "company-evaluations-templates"
          }
        },
        {
          path: "plantillas/editar-plantilla/:id",
          name: "edit-evaluation-template",
          component: () => import(
            /* webpackChunkName: "evaluations" */
            "@/views/v2/evaluations/EditEvaluationTemplateView"
          ),
          meta: {
            requireAuth: true,
            module: "evaluations",
            publicName: "Editar plantilla",
            backTo: "company-evaluations-templates"
          },
          props: true
        }
      ]
    },
    {
      path: "equipo",
      name: "team-evaluations",
      component: {
        render: (h) => h("router-view")
      },
      redirect: { name: "team-evaluations-list" },
      children: [
        {
          path: "evaluaciones",
          name: "team-evaluations-list",
          component: () => import(
            /* webpackChunkName: "evaluations" */
            "@/views/v2/evaluations/EvaluationsListView"
          ),
          beforeEnter: verifyFeature,
          meta: {
            requireAuth: true,
            module: "evaluations",
            publicName: "Evaluaciones de equipo",
            onlyMyCompany: true
          }
        },
        {
          path: "evaluaciones/crear-nueva/",
          name: "create-team-evaluation",
          component: () => import(
            /* webpackChunkName: "evaluations" */
            "@/views/v2/evaluations/CreateEvaluationView"
          ),
          meta: {
            requireAuth: true,
            module: "evaluations",
            publicName: "Crear evaluaci\xF3n",
            backTo: "team-evaluations-list",
            onlyMyCompany: true
          },
          props: true
        },
        {
          path: "evaluaciones/confirmacion-crear-nueva/",
          name: "evaluation-preview-team",
          component: () => import(
            /* webpackChunkName: "evaluations" */
            "@/views/v2/evaluations/CreateEvaluationStepTwo"
          ),
          meta: {
            requireAuth: true,
            module: "evaluations",
            publicName: "Crear evaluaci\xF3n",
            onlyMyCompany: true
          },
          props: true
        },
        {
          path: "evaluacion/:id",
          name: "summary-evaluation-team",
          component: () => import(
            /* webpackChunkName: "evaluations" */
            "@/views/v2/evaluations/SummaryEvaluation"
          ),
          meta: {
            requireAuth: true,
            module: "evaluations",
            backTo: "company-evaluations-list",
            onlyMyCompany: true
          },
          props: true,
          redirect: {
            name: "summary-evaluation-team-status"
          },
          children: [
            {
              path: "estatus",
              name: "summary-evaluation-team-status",
              component: () => import(
                /* webpackChunkName: "evaluations" */
                "@/views/v2/evaluations/SummaryEvaluationStatus"
              ),
              meta: {
                requireAuth: true,
                module: "evaluations",
                backTo: "company-evaluations-list",
                onlyMyCompany: true
              },
              props: true
            },
            {
              path: "resultados",
              name: "summary-evaluation-team-results",
              component: () => import(
                /* webpackChunkName: "evaluations" */
                "@/views/v2/evaluations/SummaryEvaluationResults"
              ),
              meta: {
                requireAuth: true,
                module: "evaluations",
                backTo: "company-evaluations-list",
                onlyMyCompany: true
              },
              props: true
            }
          ]
        }
      ]
    },
    {
      path: "mis-evaluaciones",
      name: "my-evaluations",
      component: {
        render: (h) => h("router-view")
      },
      redirect: { name: "nova-my-pending-evaluations" },
      children: [
        {
          path: "pendientes",
          name: "my-pending-evaluations",
          redirect: { name: "nova-my-pending-evaluations" }
        },
        {
          path: "respondidas",
          name: "my-answered-evaluations",
          redirect: { name: "nova-my-answered-evaluations" }
        },
        {
          path: "resultados",
          name: "my-evaluations-results",
          redirect: { name: "nova-my-results-evaluations" }
        },
        {
          path: "enviadas/:id",
          name: "sent-evaluation-result",
          component: () => import(
            /* webpackChunkName: "evaluations" */
            "@/views/v2/evaluations/SentEvaluationResultView"
          ),
          meta: {
            requireAuth: true,
            module: "evaluations",
            publicName: "Evaluaci\xF3n enviada",
            onlyMyCompany: true
          }
        },
        {
          path: "resultados/:id",
          name: "evaluation-result",
          component: () => import(
            /* webpackChunkName: "evaluations" */
            "@/views/v2/evaluations/EvaluationResultView"
          ),
          meta: {
            requireAuth: true,
            module: "evaluations",
            publicName: "Tus resultados",
            backTo: "my-evaluations-results",
            onlyMyCompany: true
          }
        },
        {
          path: "responder-evaluacion/:id",
          name: "answer-evaluation",
          component: () => import(
            /* webpackChunkName: "evaluations" */
            "@/views/v2/evaluations/AnswerEvaluationView"
          ),
          meta: {
            requireAuth: true,
            module: "evaluations",
            publicName: "Responde la evaluaci\xF3n",
            backTo: "my-pending-evaluations",
            onlyMyCompany: true
          },
          props: true
        }
      ]
    }
  ]
};
export default routes;
