import axios from "axios";
import { buildURLParams } from "@/utils/params";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;
export const bulk = {
  getStep(companyId) {
    return axios.get(`/api/v1/massive_create/process/${companyId}/`);
  },
  updateStep(companyId, payload) {
    return axios.put(`/api/v1/massive_create/process/${companyId}/`, payload);
  },
  leaveProcess(companyId) {
    return axios.put(`/api/v1/massive_create/process/${companyId}/?exit_process=true`);
  },
  getFieldsConfig() {
    return axios.get(`/api/v2/fields_configuration/`);
  },
  getFieldsByFilter(params) {
    const urlParams = buildURLParams(params);
    return axios.get(`/api/v2/fields_configuration/`, {
      params: urlParams
    });
  },
  updateField(id, payload) {
    return axios.put(`/api/v2/fields_configuration/${id}/`, payload);
  },
  updateFields(payload) {
    return axios.put(`/api/v2/fields_configuration/bulk_update/`, payload);
  },
  massiveCreate(employees) {
    return axios.post("/api/v1/massive_create/", employees);
  },
  massiveUpdate(employees) {
    return axios.put("/api/v1/massive_create/bulk_update/", employees);
  },
  getEstates() {
    return axios.get(`/api/v2/catalogs/estates/`);
  },
  getGenders() {
    return axios.get(`/api/v2/catalogs/genders/`);
  },
  getBank() {
    return axios.get(`/api/v2/catalogs/banks/`);
  },
  getCivilStatus() {
    return axios.get(`/api/v2/catalogs/civil_statuses/`);
  },
  getContracts() {
    return axios.get(`/api/v2/catalogs/contract_types/`);
  },
  getPayrolls() {
    return axios.get(`/api/v2/catalogs/payroll_schemes/`);
  },
  getMassiveEmployees() {
    return axios.get(`/api/v1/massive/employees/`);
  },
  getMassiveEmployeesFilter(filter) {
    return axios.get(`/api/v1/massive/employees/?status=${filter}`);
  },
  massiveActivate(payload) {
    return axios.post("/api/v1/massive_create/process/invite_employees/", payload);
  },
  getMassiveEmployeesByIds(ids) {
    return axios.post("/api/v1/massive/employees/dynamic/", ids);
  },
  autoSave(companyId, draft) {
    return axios.put(`/api/v1/massive_create/process/${companyId}/`, draft);
  },
  getEditingExcel(employeesIds) {
    return axios.post("/api/v2/fields_configuration/export_to_excel/", { ids: employeesIds }, {
      responseType: "arraybuffer"
    });
  },
  massiveEdit(file) {
    const formData = new FormData();
    formData.append("file", file);
    return axios.post("/api/v1/massive/employees/edit_upload_excel/", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },
  getReactivateExcel(employeesIds) {
    return axios.post("/api/v1/massive/employees/export_reactivate_employees_layout/", { ids: employeesIds }, {
      responseType: "arraybuffer"
    });
  },
  massiveReactivate(file) {
    const formData = new FormData();
    formData.append("file", file);
    return axios.post("/api/v1/massive/employees/reactivate_upload_excel/", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },
  getLayoffExcel(employeesIds) {
    return axios.post("/api/v1/massive/employees/export_layoff_employees_layout/", { ids: employeesIds }, {
      responseType: "arraybuffer"
    });
  },
  massiveLayoff(file) {
    const formData = new FormData();
    formData.append("file", file);
    return axios.post("/api/v1/massive/employees/layoff_upload_excel/", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },
  downloadDocumentsContainer(payload) {
    return axios.post("/api/v1/massive/employees/download_documents_container_file/", payload, {
      responseType: "arraybuffer"
    });
  },
  uploadEmployeesDocuments(file) {
    const formData = new FormData();
    formData.append("file", file);
    return axios.post("/api/v1/massive/employees/upload_employees_documents/", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
};
export default bulk;
