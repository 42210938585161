var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import VueMeta from "vue-meta";
import { checkRole as checkRoleFn } from "@/permissions";
import accountRoutes from "./account";
import announcementRoutes from "./announcement";
import backofficeRoutes from "./backoffice";
import calendarRoutes from "./calendar";
import customRequestsIncidents from "./customRequestIncidence";
import customRequestsIncidents2 from "./customRequestIncidence2";
import directoryRoutes from "./directory";
import documentsAndDigitalSignRoutes from "./documentAndDigitalSign";
import documentAndDigitalSignPublic from "./documentAndDigitalSignPublic";
import evaluationRoutes from "./evaluationsV2";
import internalProcess from "./internalProcess";
import notificationsRoutes from "./notifications";
import novaRoutes from "./nova";
import onboardingRoutes from "./onboarding";
import outsourcerProfileRoutes from "./osrProfile";
import payrollRoutes from "./payroll";
import payrollReceiptRoutes from "./payroll-receipt";
import permissionsRoutes from "./permissions";
import plans from "./plans";
import preonboardingRoutes from "./preonboarding";
import candidateRoutes from "./preonboardingCandidate";
import profileRoutes from "./profile";
import recruitmentRoutes from "./recruitment";
import reportsRoutes from "./reports";
import supersetDashboardsRoutes from "./supersetDashboards";
import timeClockRoutes from "./timeClock";
import timeClockRoutes2 from "./timeClockv3";
import timeOffSimulatorRoutes from "./timeOffSimulator";
import trainingsRoutes from "./trainings";
import turnoverReports from "./turnoverReports";
import settingsV1Routes from "./v1/settings";
import ZKTecoRouted from "./zkteco";
import { utils } from "@/api";
import refreshToken from "@/utils/refreshToken";
Vue.use(Router);
Vue.use(VueMeta);
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/mantenimiento",
      name: "mantenimiento",
      meta: {
        title: "Mantenimiento",
        module: "Mantenimiento"
      },
      component: () => import(
        /* webpackChunkName: "common" */
        "@/views/Mantenimiento"
      )
    },
    {
      path: "/vacantes/:company/:id",
      name: "jobs-page",
      meta: {
        title: "Vacantes en ",
        module: "Reclutamiento",
        metaTags: [
          {
            name: "description",
            content: "Vacantes en "
          },
          {
            property: "og:description",
            content: "Vacantes en "
          }
        ]
      },
      component: () => import(
        /* webpackChunkName: "recruitment-public" */
        "@/views/v2/recruitment/RecruitmentVacancies"
      )
    },
    {
      path: "/vacante/:company/:id",
      name: "job-post",
      meta: {
        title: "Vacante en ",
        module: "Reclutamiento",
        metaTags: [
          {
            name: "description",
            content: "Vacante en "
          },
          {
            property: "og:description",
            content: "Vacante en "
          }
        ]
      },
      component: () => import(
        /* webpackChunkName: "recruitment-public" */
        "@/views/v2/recruitment/RecruitmentVacancyDetail"
      )
    },
    ...accountRoutes,
    onboardingRoutes,
    timeOffSimulatorRoutes,
    backofficeRoutes,
    ...candidateRoutes,
    {
      path: "/reloj-checador/codigo-qr/:company/:id",
      name: "check-qr-code",
      meta: {
        title: "Reloj Checador",
        module: "Reloj Checador"
      },
      component: () => import(
        /* webpackChunkName: "timeclock-public" */
        "@/views/TimeClockQrCodeView"
      )
    },
    {
      path: "/",
      component: () => import(
        /* webpackChunkName: "common" */
        "@/layouts/GralLayout"
      ),
      meta: {
        requireAuth: true
      },
      children: [
        {
          path: "/",
          name: "home",
          component: () => import(
            /* webpackChunkName: "home" */
            "@/views/HomeLayout"
          ),
          meta: {
            requireAuth: true
          },
          children: [
            {
              path: "sin-acceso",
              name: "unauthorized",
              meta: {
                requireAuth: true
              },
              component: () => import(
                /* webpackChunkName: "common" */
                "@/views/authorization/Unauthorized"
              )
            },
            {
              path: "dashboard",
              name: "dashboard",
              component: () => import(
                /* webpackChunkName: "home" */
                "@/views/HomeDashboard"
              ),
              meta: {
                module: "home",
                requireAuth: true,
                publicName: "Bienvenid@ a Worky"
              }
            },
            {
              path: "dashboard/:id",
              name: "announcement-detail",
              component: () => import(
                /* webpackChunkName: "home" */
                "@/views/HomeDashboard"
              ),
              meta: {
                module: "home",
                requireAuth: true,
                publicName: "Bienvenid@ a Worky"
              }
            },
            {
              path: "checklist",
              name: "checklist",
              component: () => import(
                /* webpackChunkName: "home" */
                "@/views/HomeChecklist"
              ),
              meta: {
                requireAuth: true,
                publicName: "Bienvenid@ a Worky"
              }
            }
          ]
        },
        announcementRoutes,
        directoryRoutes,
        documentsAndDigitalSignRoutes,
        customRequestsIncidents,
        customRequestsIncidents2,
        recruitmentRoutes,
        settingsV1Routes,
        profileRoutes,
        outsourcerProfileRoutes,
        calendarRoutes,
        permissionsRoutes,
        preonboardingRoutes,
        timeClockRoutes,
        timeClockRoutes2,
        trainingsRoutes,
        plans,
        internalProcess,
        payrollRoutes,
        ...ZKTecoRouted,
        ...novaRoutes,
        {
          path: "/beneficios",
          name: "benefits",
          query: {
            coru: false
          },
          component: () => import(
            /* webpackChunkName: "common" */
            "@/views/BenefitsLayout"
          ),
          meta: {
            requireAuth: true,
            publicName: "Beneficios",
            module: "benefits"
          },
          children: [
            {
              name: "benefits-worky",
              path: "worky/:subPage?",
              component: () => import(
                /* webpackChunkName: "nova" */
                "@/components/nova/NovaMountPoint"
              ),
              meta: {
                requireAuth: true,
                publicName: "Beneficios",
                module: "benefits"
              }
            },
            {
              name: "benefits-company",
              path: "mis-beneficios",
              component: () => import("@/modules/Benefits/views/CompanyBenefits"),
              meta: {
                requireAuth: true,
                publicName: "Beneficios",
                module: "benefits"
              }
            }
          ]
        },
        payrollReceiptRoutes,
        reportsRoutes,
        turnoverReports,
        ...supersetDashboardsRoutes,
        {
          path: "/organigrama",
          name: "orgchart",
          component: () => import(
            /* webpackChunkName: "common" */
            "@/views/OrgchartLayout"
          ),
          meta: {
            requireAuth: true,
            publicName: "Organigrama",
            module: "chart"
          }
        },
        evaluationRoutes,
        {
          path: "/reloj-checador",
          name: "time-clock-template",
          component: () => import(
            /* webpackChunkName: "timeclock" */
            "@/views/TimeClockLayout"
          ),
          meta: {
            requireAuth: true,
            publicName: "Reloj Checador",
            module: "Reloj Checador"
          }
        },
        ...notificationsRoutes
      ]
    },
    {
      path: "*",
      name: "not-found",
      component: () => import(
        /* webpackChunkName: "common" */
        "@/views/NotFound"
      )
    },
    {
      path: "/403",
      name: "not-allowed",
      component: () => import(
        /* webpackChunkName: "common" */
        "@/views/NotFound"
      ),
      props: {
        isNotAllowed: true
      }
    },
    documentAndDigitalSignPublic
  ],
  scrollBehavior() {
    return {
      top: 0,
      behavior: "smooth"
    };
  }
});
let isTokenValidated = false;
let isRetried = false;
const validateTokenAndRefresh = () => __async(void 0, null, function* () {
  try {
    if (!isTokenValidated) {
      yield utils.validateToken();
    }
    isTokenValidated = true;
    return true;
  } catch (_) {
  }
  if (!isTokenValidated && !isRetried) {
    try {
      yield refreshToken();
      isRetried = true;
      return validateTokenAndRefresh();
    } catch (_) {
    }
  }
  return false;
});
router.beforeEach((to, from, next) => __async(void 0, null, function* () {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _A;
  if (to.meta && "permission" in to.meta && "module" in to.meta) {
    const permissions = (_e = (_d = (_c = (_b = (_a = store) == null ? void 0 : _a.state) == null ? void 0 : _b.company) == null ? void 0 : _c.permissions) == null ? void 0 : _d.myPermissions) != null ? _e : [];
    const hasPermission = permissions.some((permission) => permission.kind === "module" && permission.slug === `${to.meta.module}-x-${to.meta.module}` && permission.policy === "allow");
    if (permissions.length > 0 && !hasPermission) {
      next({ name: "dashboard" });
    }
  }
  const checkRole = checkRoleFn(store);
  const tokenLS = localStorage.getItem("token");
  const refreshTockenLS = localStorage.getItem("refresh");
  const { meta = null } = to || {};
  const { requireAuth = false, module: metaModule = null, notValidateModule = false } = meta || {};
  if (meta && requireAuth && metaModule && !notValidateModule) {
    if (!tokenLS) {
      next("/iniciar-sesion");
      return;
    }
    const { user = {} } = ((_g = (_f = store) == null ? void 0 : _f.state) == null ? void 0 : _g.me) || {};
    const { modules = [], role: userRole = "" } = user || {};
    const userModules = modules || [];
    const metaModuleInUserModules = userModules.find((usrModule) => usrModule.module === metaModule) || null;
    const isWAUser = userRole === "WA";
    if (isWAUser) {
      localStorage.setItem("watoken", tokenLS);
      localStorage.setItem("warefresh", refreshTockenLS);
      next({ name: "backoffice" });
      return;
    }
    if (!(metaModuleInUserModules == null ? void 0 : metaModuleInUserModules.is_active)) {
      next({ name: "dashboard" });
      return;
    }
    if (!("can_view_boss" in metaModuleInUserModules) && !("can_view_employee" in metaModuleInUserModules)) {
      next();
      return;
    }
    if (meta.module === "document_editor" && !userModules.find((mod) => mod.module === "directory")) {
      next({ name: "dashboard" });
      return;
    }
    const isProfilePage = to.path.includes("/empleado/") && to.params.url === user.url_username && metaModuleInUserModules.module === "directory";
    if (!isProfilePage) {
      if (!checkRole("hra") && checkRole("boss") && !(metaModuleInUserModules == null ? void 0 : metaModuleInUserModules.can_view_boss)) {
        next({ name: "unauthorized" });
        return;
      }
      if (checkRole("em") && !checkRole("boss") && !metaModuleInUserModules.can_view_employee) {
        next({ name: "unauthorized" });
        return;
      }
    }
  }
  if (to.meta && to.meta.requireAuth && "role" in to.meta) {
    const roleToSearch = to.meta.role || [];
    const hasRoleLevel = roleToSearch.some((role) => checkRole(role.toLowerCase()));
    if (!hasRoleLevel) {
      next({ name: "dashboard" });
      return;
    }
  }
  if ((_h = to == null ? void 0 : to.meta) == null ? void 0 : _h.onlyMyCompany) {
    if (!checkRole("mycompany")) {
      next({ name: "dashboard" });
    }
  }
  if (to.meta.validateTab && !checkRole("hra")) {
    const { modules } = (_l = (_k = (_j = (_i = store) == null ? void 0 : _i.state) == null ? void 0 : _j.me) == null ? void 0 : _k.user) != null ? _l : { modules: [] };
    const { validateTab } = to.meta;
    const tabs = modules.flatMap((mod) => mod.modules_tabs.filter((tab) => tab.source_kind === "web"));
    if (tabs.length > 0) {
      const tabFounded = tabs.find((tab) => tab.tab_key === validateTab.key && (!validateTab.subMenu || tab.sub_menu_key === validateTab.subMenu));
      const level = typeof validateTab.level === "function" ? validateTab.level(to) : validateTab.level;
      if (tabFounded && level === "both") {
        if (checkRole("boss") && !tabFounded.can_view_boss) {
          next({ name: "unauthorized" });
          return;
        }
        if (!checkRole("boss") && !tabFounded.can_view_employee) {
          next({ name: "unauthorized" });
          return;
        }
      } else if (tabFounded && !tabFounded[`can_view_${level}`]) {
        next({ name: "unauthorized" });
        return;
      }
    }
  }
  if (to.params.path) {
    store.dispatch("user/setPath", to.params.path);
  }
  if (store.state.me.user.is_worky_lite && to.path !== "/worky-lite" && to.path !== "/iniciar-sesion" && to.path !== "/login") {
    next("/worky-lite");
  }
  if (localStorage.maintenanceModeWeb === "true" && to.path !== "/mantenimiento") {
    next("/mantenimiento");
  }
  if (to.meta.requireAuth) {
    if (to.meta.requireAuth && !localStorage.getItem("token")) {
      localStorage.redirectPath = to.path;
      next("/iniciar-sesion");
    } else if (!to.meta.requireAuth && localStorage.getItem("token")) {
      next("/");
    }
    if (to.meta.requireAuth && localStorage.getItem("token")) {
      const isValidated = yield validateTokenAndRefresh();
      if (!isValidated) {
        next("/iniciar-sesion");
        return;
      }
    }
    const isRouteForHRAOnly = [
      "directory-employees-batch",
      "directory-status"
    ];
    const isHraOrAnalyzer = [
      "reports"
    ];
    const isHraOrEvaluator = [
      "company-evaluations-template",
      "company-evaluations-list",
      "company-evaluations-archive"
    ];
    const isRouteForHRAorOSR = [
      "directory-former-employees"
    ];
    const isRouteForOSROnly = [
      "osr-profile"
    ];
    if (isRouteForHRAOnly.includes(to.name) && store.state.me.user.role !== "HRA" || isRouteForOSROnly.includes(to.name) && store.state.me.user.role !== "OSR" || isRouteForHRAorOSR.includes(to.name) && store.state.me.user.role !== "HRA" && store.state.me.user.role !== "OSR" || isHraOrEvaluator.includes(to.name) && store.state.me.user.role !== "HRA" && !store.state.me.user.is_evaluator) {
      next({ name: "not-allowed" });
    }
    if (isRouteForHRAOnly.includes(to.name) && store.state.me.user.role !== "HRA" || isHraOrAnalyzer.includes(to.name) && (store.state.me.user.role !== "HRA" && !((_q = (_p = (_o = (_n = (_m = store) == null ? void 0 : _m.state) == null ? void 0 : _n.me) == null ? void 0 : _o.user) == null ? void 0 : _p.employee) == null ? void 0 : _q.is_analyzer))) {
      next({ name: "not-allowed" });
    }
    if (to.name === "home") {
      if (store.state.me.user.role === "HRA" && ((_v = (_u = (_t = (_s = (_r = store) == null ? void 0 : _r.state) == null ? void 0 : _s.me) == null ? void 0 : _t.user) == null ? void 0 : _u.company) == null ? void 0 : _v.is_checklisted)) {
        next("/dashboard");
      } else if (store.state.me.user.role === "HRA" && !((_A = (_z = (_y = (_x = (_w = store) == null ? void 0 : _w.state) == null ? void 0 : _x.me) == null ? void 0 : _y.user) == null ? void 0 : _z.company) == null ? void 0 : _A.is_checklisted)) {
        next("/dashboard");
      } else {
        next("/dashboard");
      }
    } else {
      next();
    }
  } else {
    next();
  }
}));
router.beforeEach((to, from, next) => {
  const { publicName = "" } = (to == null ? void 0 : to.meta) || {};
  document.title = `${publicName ? `${publicName} - ` : ""}Worky RH`;
  next();
});
router.afterEach((to, from) => {
  const { module = void 0 } = (to == null ? void 0 : to.meta) || {};
  if (module === void 0) {
    return;
  }
  if (window.dataLayer) {
    window.dataLayer.push({ dimension5: module });
  }
});
router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    window.location.reload();
  } else if (/ChunkLoadError:.*failed./i.test(error.message) && navigator.onLine) {
    window.location.reload();
  } else if (/Loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    window.location.reload();
  } else if (/expected expression, got '</i.test(error.message) && navigator.onLine) {
    window.location.reload();
  }
});
export default router;
