var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import "@worky/nova-front/dist/style.css";
import "@worky/ui-legacy/dist/style.css";
import Vue from "vue";
import VueCookie from "vue-cookie";
import VueGtm from "@gtm-support/vue2-gtm";
import Notifications from "vue-notification";
import vOutsideEvents from "vue-outside-events";
import VTooltip from "v-tooltip";
import VueLottiePlayer from "vue-lottie-player";
import VueNumeric from "vue-numeric";
import { abilitiesPlugin } from "@casl/vue";
import VueHotkey from "v-hotkey";
import feature from "vue-feature-toggle";
import wysiwyg from "vue-wysiwyg";
import VueQuillEditor from "vue-quill-editor";
import * as Sentry from "@sentry/vue";
import { rewriteFramesIntegration } from "@sentry/integrations";
import { remoteConfig } from "@/firebase";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import OrgChartWorky from "@worky/orgchart-worky";
import Vuelidate from "vuelidate";
import VueMq from "vue-mq";
import lineClamp from "vue-line-clamp";
import VueGeolocationApi from "vue-geolocation-api";
import GMapVue from "gmap-vue";
import skeleton from "@/directives/skeleton";
import App from "./App";
import ApiClient from "./api";
import config from "./config";
import WorkyPermissions from "./permissions";
import router from "./router";
import store from "./store";
import WorkyFeatures from "./features";
import "@worky/ui-legacy/dist/style.css";
import "./registerServiceWorker";
Vue.prototype.$maintenanceModeWeb = false;
if (process.env.VUE_APP_MAINTENANCE_CAPABLE === "true") {
  remoteConfig.fetchAndActivate(0).then(() => {
    const superAwesomeFeature = remoteConfig.getValue("maintenanceModeWeb").asBoolean();
    localStorage.maintenanceModeWeb = superAwesomeFeature;
  }).catch((err) => {
    console.error(err);
  });
}
Vue.use(ApiClient);
Vue.use(vOutsideEvents);
Vue.use(Notifications);
Vue.use(VueNumeric);
Vue.use(Vuelidate);
Vue.use(abilitiesPlugin);
Vue.use(VueCookie);
Vue.use(VueHotkey);
Vue.use(VueLottiePlayer);
Vue.use(WorkyPermissions, store);
Vue.use(WorkyFeatures, store);
Vue.use(wysiwyg, {
  hideModules: {
    image: true,
    link: true
  },
  forcePlainTextOnPaste: true
});
Vue.use(OrgChartWorky);
Vue.use(VueQuillEditor);
Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    sm: 980,
    md: 1440,
    de: 1656,
    //1440,
    lg: Infinity
  },
  defaultBreakpoint: "md"
  // customize this for SSR
});
Vue.use(lineClamp);
Vue.use(VueGeolocationApi);
Vue.use(skeleton);
Vue.use(GMapVue, {
  load: {
    key: process.env.VUE_APP_MAPS_API_KEY,
    libraries: "places"
  }
});
Vue.use(skeleton);
Vue.use(VTooltip, {
  defaultTemplate: `<div class="v-tooltip" role="tooltip">
    <div class="tooltip-arrow"></div><div class="tooltip-inner"></div>
  </div>`,
  defaultBoundariesElement: "div"
});
feature.visibility("dashboard", (rule) => process.env.NODE_ENV === "production" && rule.variant && rule.variant !== "new" || process.env.NODE_ENV !== "production" && (!rule.variant || rule.variant === "new"));
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: config.dsn,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay(),
      rewriteFramesIntegration()
    ],
    debug: config.debug,
    environment: config.sentryEnv,
    blacklistUrls: [
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extensions:\/\//i
    ],
    tracingOptions: {
      trackComponents: true
    },
    release: config.sentryRelease,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1
  });
}
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GOOGLE_TAG_MANAGER,
  defer: false,
  compatibility: false,
  enabled: true,
  debug: true,
  loadScript: true,
  vueRouter: router,
  ignoredViews: [],
  trackOnNextTick: true
});
Vue.prototype.$eventHub = new Vue();
Vue.config.productionTip = false;
const userDimensions = JSON.parse(localStorage.getItem("userDimensions")) || {};
const idenfityEvent = (window == null ? void 0 : window.amplitude) ? new window.amplitude.Identify() : null;
const dimensionsDict = {
  dimension1: "Company",
  dimension2: "Role",
  dimension3: "Level",
  dimension4: "Company Size",
  dimension6: "Company Type",
  dimension7: "IsOrganizationUser"
};
Vue.mixin({
  methods: {
    $alertInfo(text) {
      this.$notify({
        title: "",
        text
      });
    },
    $alertWarning(text) {
      this.$notify({
        max: 3,
        type: "warn",
        title: "",
        text
      });
    },
    $alertSuccess(text) {
      this.$notify({
        type: "success",
        title: "",
        text
      });
    },
    $alertError(text) {
      this.$notify({
        type: "error",
        title: "",
        text
      });
    },
    $sendEvent(category, action = "", label = "", additionalProps = {}) {
      if (!category)
        return;
      if (this.$gtm.enabled()) {
        window.dataLayer.push(__spreadValues({
          category,
          action,
          label,
          "interaction-type": false,
          event: "WorkyInteraction",
          target: category,
          "target-properties": label,
          value: label
        }, userDimensions));
      }
      const environment = process.env.NODE_ENV;
      const eventProperties = __spreadValues({
        category,
        label
      }, additionalProps);
      if (window.amplitude && environment === "production") {
        amplitude == null ? void 0 : amplitude.track(action, eventProperties);
      } else {
        console.info(`${environment}-event: ${action}`, eventProperties);
      }
    },
    $setVariable(variable) {
      var _a, _b;
      const environment = process.env.NODE_ENV;
      if (!variable)
        return;
      if (this.$gtm.enabled()) {
        (_a = window.dataLayer) == null ? void 0 : _a.push(variable);
        Object.keys(variable).forEach((key) => {
          userDimensions[key] = variable[key];
          if (dimensionsDict[key]) {
            idenfityEvent == null ? void 0 : idenfityEvent.set(dimensionsDict[key], variable[key]);
          }
        });
        localStorage.setItem("userDimensions", JSON.stringify(userDimensions));
        if ((window == null ? void 0 : window.amplitude) && environment === "production") {
          (_b = window == null ? void 0 : window.amplitude) == null ? void 0 : _b.identify(idenfityEvent);
        } else {
          console.info(`${environment}-amplitude-identify`, idenfityEvent);
        }
      }
    },
    $setUserId(userId) {
      if (!window.amplitude || process.env.NODE_ENV !== "production")
        return;
      amplitude == null ? void 0 : amplitude.setUserId(userId);
    }
  }
});
new Vue({
  router,
  store,
  components: { feature },
  render: (h) => h(App)
}).$mount("#app");
