export default [
  {
    path: "/nova",
    name: "nova",
    component: {
      render: (h) => h("router-view")
    },
    meta: {
      requireAuth: false
    },
    children: [
      {
        path: "evaluaciones",
        name: "nova-surveys",
        component: {
          render: (h) => h("router-view")
        },
        redirect: { name: "nova-evaluations" },
        children: [
          {
            path: ":tab?",
            name: "nova-evaluations",
            component: () => import(
              /* webpackChunkName: "nova" */
              "@/components/nova/NovaMountPoint"
            ),
            meta: {
              requireAuth: true,
              module: "evaluations",
              publicName: "Evaluaciones de la empresa"
            }
          }
        ]
      },
      {
        path: "evaluaciones/resultados/:id?",
        name: "nova-results-evaluations",
        component: () => import(
          /* webpackChunkName: "nova" */
          "@/components/nova/NovaMountPoint"
        ),
        meta: {
          requireAuth: false,
          module: "evaluations",
          publicName: "Resultado de la evaluaci\xF3n"
        }
      },
      {
        path: "evaluaciones/build/evaluation/:id?",
        name: "nova-evaluations-build-evaluation",
        component: () => import(
          /* webpackChunkName: "nova" */
          "@/components/nova/NovaMountPoint"
        ),
        beforeEnter: (to, from, next) => {
          to.meta.publicName = to.params.id ? "Editar evaluaci\xF3n" : "Crear evaluaci\xF3n";
          next();
        },
        meta: {
          requireAuth: true,
          module: "evaluations",
          backTo: "nova-evaluations"
        }
      },
      {
        path: "evaluaciones/build/template/:id?",
        name: "nova-valuations-build-template",
        component: () => import(
          /* webpackChunkName: "nova" */
          "@/components/nova/NovaMountPoint"
        ),
        beforeEnter: (to, from, next) => {
          to.meta.publicName = to.params.id ? "Editar plantilla" : "Nueva plantilla";
          next();
        },
        meta: {
          requireAuth: true,
          module: "evaluations",
          backTo: "nova-evaluations"
        }
      },
      {
        path: "evaluaciones/answer/:id/:token?",
        name: "nova-evaluations-answer-evaluation",
        component: () => import(
          /* webpackChunkName: "nova" */
          "@/components/nova/NovaMountPoint"
        ),
        meta: {
          requireAuth: false,
          module: "evaluations",
          publicName: "Responde la evaluaci\xF3n",
          backTo: "nova-my-pending-evaluations"
        }
      },
      {
        path: "evaluaciones/build/evaluation/dataforsend",
        name: "nova-evaluations-data-for-send",
        component: () => import(
          /* webpackChunkName: "nova" */
          "@/components/nova/NovaMountPoint"
        ),
        meta: {
          requireAuth: true,
          module: "evaluations",
          publicName: "Datos para enviar",
          backTo: "nova-evaluations"
        }
      },
      {
        path: "evaluaciones/status/:id?",
        name: "nova-evaluations-status",
        component: () => import(
          /* webpackChunkName: "nova" */
          "@/components/nova/NovaMountPoint"
        ),
        beforeEnter: (to, from, next) => {
          to.meta.publicName = to.params.id ? "Estado de la Evaluaci\xF3n" : "Evaluaciones de la empresa";
          next();
        },
        meta: {
          requireAuth: true,
          module: "evaluations",
          backTo: "nova-evaluations"
        }
      },
      {
        path: "evaluacion-sin-detalle/:reason",
        name: "nova-my-evaluation-cant-be-answered",
        component: () => import(
          /* webpackChunkName: "nova" */
          "@/components/nova/NovaMountPoint"
        ),
        beforeEnter: (to, from, next) => {
          next();
        },
        meta: {
          requireAuth: true,
          module: "evaluations",
          backTo: "nova-my-evaluations"
        }
      },
      {
        path: "resultados/detalle/:id/:token?",
        name: "nova-my-results-evaluations-detail",
        component: () => import(
          /* webpackChunkName: "nova" */
          "@/components/nova/NovaMountPoint"
        ),
        meta: {
          requireAuth: false,
          module: "evaluations",
          publicName: "Mis resultados de la evaluaci\xF3n",
          backTo: "nova-my-results-evaluations"
        }
      },
      {
        path: "mis-evaluaciones",
        name: "nova-my-evaluations",
        component: {
          render: (h) => h("router-view")
        },
        redirect: { name: "nova-my-pending-evaluations" },
        children: [
          {
            path: "pendientes",
            name: "nova-my-pending-evaluations",
            component: () => import(
              /* webpackChunkName: "nova" */
              "@/components/nova/NovaMountPoint"
            ),
            meta: {
              requireAuth: true,
              module: "evaluations",
              publicName: "Mis evaluaciones"
            }
          },
          {
            path: "respondidas",
            name: "nova-my-answered-evaluations",
            component: () => import(
              /* webpackChunkName: "nova" */
              "@/components/nova/NovaMountPoint"
            ),
            meta: {
              requireAuth: true,
              module: "evaluations",
              publicName: "Mis evaluaciones"
            }
          },
          {
            path: "resultados",
            name: "nova-my-results-evaluations",
            component: () => import(
              /* webpackChunkName: "nova" */
              "@/components/nova/NovaMountPoint"
            ),
            meta: {
              requireAuth: false,
              module: "evaluations",
              publicName: "Mis evaluaciones"
            }
          }
        ]
      }
    ]
  }
];
