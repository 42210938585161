var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import store from "@/store";
const routes = {
  path: "/directorio",
  component: () => import(
    /* webpackChunkName: "directory" */
    "@/views/DirectoryLayout"
  ),
  name: "directory",
  redirect: { name: "directory-employees" },
  children: [
    {
      path: "colaboradores",
      name: "directory-employees",
      component: () => import(
        /* webpackChunkName: "directory" */
        "@/views/directory/collaborators/DirectoryCollaborators"
      ),
      meta: {
        requireAuth: true,
        module: "directory",
        publicName: "Listado de Colaboradores",
        permission: true
      }
    },
    {
      path: "configurar-datos",
      name: "configure-data",
      component: () => import(
        /* webpackChunkName: "directory" */
        "@/views/directory/DirectoryConfigureData"
      ),
      beforeEnter: (to, from, next) => {
        const permiso = store.state.me.user.role === "HRA";
        if (!permiso) {
          next({ name: "not-allowed" });
        } else
          next();
      },
      meta: {
        requireAuth: true,
        module: "directory",
        publicName: "Configurar datos",
        backTo: "back",
        permission: true
      }
    },
    {
      path: "carga-masiva",
      name: "directory-employees-batch",
      component: () => import(
        /* webpackChunkName: "directory" */
        "@/views/DirectoryCreateBatch"
      ),
      meta: {
        requireAuth: true,
        module: "directory",
        publicName: "Carga masiva de colaboradores",
        backTo: "back",
        permission: true
      }
    },
    {
      path: "edicion-masiva",
      beforeEnter: (to, from, next) => __async(void 0, null, function* () {
        if (!store.state.company.permissions.myPermissions.find(
          (permission) => permission.slug.includes("directory")
        )) {
          next();
        }
        if (store.state.company.permissions.myPermissions.filter(
          (permission) => permission.slug.includes("directory") && permission.policy === "read"
        ).length > 0) {
          next({ name: "not-allowed" });
        }
        next();
      }),
      name: "directory-employees-edit",
      component: () => import(
        /* webpackChunkName: "directory" */
        "@/views/DirectoryEditBatch"
      ),
      meta: {
        requireAuth: true,
        module: "directory",
        publicName: "Edici\xF3n masiva de colaboradores",
        backTo: "directory-employees",
        permission: true
      }
    },
    {
      path: "excolaboradores",
      name: "directory-former-employees",
      component: () => import(
        /* webpackChunkName: "directory" */
        "@/views/DirectoryFormerEmployees"
      ),
      meta: {
        requireAuth: true,
        module: "directory",
        publicName: "Listado de Ex Colaboradores",
        permission: true
      }
    },
    {
      path: "en-proceso",
      name: "directory-status",
      component: () => import(
        /* webpackChunkName: "directory" */
        "@/views/DirectoryStatus"
      ),
      meta: {
        requireAuth: true,
        module: "directory",
        publicName: "Estatus de Colaboradores",
        permission: true
      }
    },
    {
      path: "actualizacion",
      name: "directory-update",
      component: () => import(
        /* webpackChunkName: "directory" */
        "@/views/DirectoryStatusUpdate"
      ),
      beforeEnter: (to, from, next) => {
        const permiso = store.state.me.user.role === "HRA";
        if (!permiso) {
          next({ name: "not-allowed" });
        } else
          next();
      },
      meta: {
        requireAuth: true,
        module: "directory",
        publicName: "Estatus de actualizaci\xF3n a Colaboradores",
        permission: true
      }
    },
    {
      path: "equipo",
      name: "directory-team",
      component: () => import(
        /* webpackChunkName: "directory" */
        "@/views/DirectoryTeam"
      ),
      meta: {
        requireAuth: true,
        module: "directory",
        publicName: "Listado de Equipo",
        onlyMyCompany: true,
        permission: true,
        validateTab: {
          key: "tab_team_list",
          level: "boss",
          subMenu: "my_team"
        }
      }
    },
    {
      path: "documentos",
      name: "directory-bulk-files",
      component: () => import(
        /* webpackChunkName: "directory" */
        "@/views/DirectoryBulkFiles"
      ),
      beforeEnter: (to, from, next) => {
        const permiso = store.state.me.user.role === "HRA";
        if (!permiso) {
          next({ name: "not-allowed" });
        } else
          next();
      },
      meta: {
        requireAuth: true,
        module: "directory",
        publicName: "Carga de Archivos",
        permission: true
      }
    }
  ]
};
export default routes;
